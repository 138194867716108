import {} from '@frk/graphql-types';
import dateUtils from '../date';

export const TERMS_OF_CONTRACT_LATEST_UPDATE = '2023-11-30';

export function getLatestTerms(termsList: Date[]): Date {
  const latestTerms = termsList[termsList.length - 1];

  return latestTerms;
}

export function hasAcceptedLatestTerms(termsList: Date[]): boolean {
  const latestTerms = getLatestTerms(termsList);

  return latestTerms ? dateUtils.isDateAfter(latestTerms, TERMS_OF_CONTRACT_LATEST_UPDATE) : false;
}
